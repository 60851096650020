import { IconComponent } from '@vs/ui/vs-icons';
import { ReactNode, createContext } from 'react';

import { PathMeta } from './type';

type LazyView =
  | React.LazyExoticComponent<() => JSX.Element | null>
  | React.LazyExoticComponent<
      React.MemoExoticComponent<() => JSX.Element | null>
    >
  | React.FC;

export type MenuMeta = {
  /** Sorting order of the path in menu */
  order: number;
  /** Icon of the path in menu */
  Icon?: IconComponent;
  /** I18n key of the path in menu */
  labelI18n: string;
  /** policy string of path in menu, if user doesn't have policy, menu option will be disabled */
  policyString?: string;
};

export type Base = {
  /** Menu meta info of path */
  menuMeta?: MenuMeta;
  /* Whether to hide this path or link */
  hide?: boolean;
};
export type ChildViews = {
  /** back button i18n key */
  backBtni18n?: string;
} & RouteConfig;

export type RouteConfig = {
  /** Indicate this is spa path */
  type: 'path';
  /** Pathname */
  path: string;
  /** Lazy loaded component */
  View: LazyView;
  /** Configs of child paths */
  childViews?: ChildViews[];
  /** Meta info of path */
  meta?: PathMeta;
  /** Whether this path as fallback path */
  isFallbackPath?: boolean;
} & Base;

export type FlattenRouteConfig = Omit<RouteConfig, 'type' | 'path'> & {
  type?: string;
  path?: string;
};

export type LinkConfig = {
  /** Indicate this is external link */
  type: 'link';
  /** External link */
  href: string;
} & Base;

export type PathConfigs = (RouteConfig | LinkConfig)[];

export const context = createContext<PathConfigs>([]);

export type PropsProviderSpaPathsConfig = {
  /** Paths config */
  configs: PathConfigs;
  /** Valid react node */
  children: ReactNode;
};
export function ProviderSpaPathsConfig(props: PropsProviderSpaPathsConfig) {
  const { configs, children } = props;
  return <context.Provider value={configs}>{children}</context.Provider>;
}

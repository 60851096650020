type Validator = (value: unknown) => boolean;

type ErrorTarget = Record<string, unknown>;

const logical = {
  or: (validators: Validator[]) => (value: unknown) =>
    validators.some(vl => vl(value)),
  and: (validators: Validator[]) => (value: unknown) =>
    validators.every(vl => vl(value)),
};

const isString = (value: unknown): value is string => typeof value === 'string';

const isNumber = (value: unknown): value is number => typeof value === 'number';

const isObject = (value: unknown): value is object =>
  value !== null && typeof value === 'object';

const isStringArray = (value: unknown): value is string[] =>
  Array.isArray(value) && value.every(isString);

const generateRulesValidator = <T extends Record<string, unknown>>(rules: {
  [key in keyof T]: Validator;
}) => {
  return (target: ErrorTarget) =>
    Object.entries<Validator>(rules).every(
      ([key, validator]) => key in target && validator(target[key])
    );
};

export type VsErrorDataMsgDict = {
  [key: string]: string | number | object;
};

export type VsErrorData = {
  code: string;
  message: VsErrorDataMsgDict | string | string[];
  path: string;
  request_id: string;
  service: string;
  status_code: number;
  timestamp: string;
  errors?: unknown[];
};
const vsApiDataValidator = generateRulesValidator<VsErrorData>({
  code: isString,
  message: logical.or([isString, isStringArray, isObject]),
  path: isString,
  request_id: isString,
  service: isString,
  status_code: isNumber,
  timestamp: isString,
});
export function isErrorDataOfVsApi(
  errorData: unknown
): errorData is VsErrorData {
  return isObject(errorData) && vsApiDataValidator(errorData as ErrorTarget);
}

const vsApiResponseValidator = generateRulesValidator({
  data: isErrorDataOfVsApi,
  status: isNumber,
});
export function isErrorResponseOfVsApi(error: unknown): error is {
  status: number;
  data: VsErrorData;
} {
  return isObject(error) && vsApiResponseValidator(error as ErrorTarget);
}

const vsGraphQLValidator = generateRulesValidator({
  message: isString,
  name: isString,
});
export function isErrorResponseOfVsGrapQl(error: unknown): error is {
  message: string;
  name: string;
} {
  return isObject(error) && vsGraphQLValidator(error as ErrorTarget);
}

export type RtkErrorResponseOfVsApi = {
  status: 'VS_API_ERROR';
  originalStatus: number;
  data: VsErrorData;
};

export function transformErrorResponseOfVsApi(error: {
  status: number;
  data: VsErrorData;
}): RtkErrorResponseOfVsApi {
  return {
    status: 'VS_API_ERROR',
    originalStatus: error.status,
    data: error.data,
  };
}

export function isErrorOfVsApi(
  error: unknown
): error is RtkErrorResponseOfVsApi {
  return (
    typeof error === 'object' &&
    error != null &&
    'status' in error &&
    error.status === 'VS_API_ERROR'
  );
}

type ResClientError = {
  status: 400;
  data: {
    request_id: string;
    code: string;
    status_code: number;
    service: string;
    message: {
      [key in string]: string | number | object;
    };
    path: string;
    timestamp: string;
  };
};

export function isErrorOfClient(error: unknown): error is ResClientError {
  return isObject(error) && 'status' in error && error.status === 400;
}

import { withSentryReactRouterV6Routing } from '@vs/sentry-react-router-integration';
import { PortalBackdrop } from '@vs/ui/portal/backdrop';
import { Suspense, memo, useContext, useMemo } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { RouteConfig, context } from './spa-path-config-provider';
import { flattenAppPathsAndView } from './utils';

export const SpaRoutesByPathsConfig = memo(() => {
  const location = useLocation();
  const pathsConfig = useContext(context);
  const pathsAndView = flattenAppPathsAndView(pathsConfig);
  const defaultFallbackPath = pathsAndView.find(
    p => p.type === 'path' && p.isFallbackPath
  ) as RouteConfig;

  const SentryRoutes = useMemo(
    () => withSentryReactRouterV6Routing(Routes),
    []
  );

  return (
    <Suspense fallback={<PortalBackdrop open type="module_init" />}>
      <SentryRoutes>
        {pathsAndView
          .map(p => <Route key={p.path} path={p.path} element={<p.View />} />)
          .flat()}
        {defaultFallbackPath !== undefined && (
          <Route
            path="*"
            element={
              <Navigate
                to={{
                  pathname: defaultFallbackPath.path,
                  search: location.search,
                }}
              />
            }
          />
        )}
      </SentryRoutes>
    </Suspense>
  );
});

import { vsAuthApiBase as api } from './vsAuthApiBase';

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    loginTargetByQrCode: build.mutation<void, ArgsloginTargetByQrCode>({
      query: ({ account_id, interaction_id }) => ({
        method: 'POST',
        url: '/auth/v1/api/account/qrcode',
        body: {
          account_id,
          interaction_id,
        },
      }),
    }),
  }),
});
export { injectedRtkApi as vsAuthApiInjected };
export type ArgsResetPassword = {
  account: string;
  referer_uri: string;
};

export interface RespChangePassword {
  nativeCode: string;
  status: number;
  responseCode: number;
  messageCode: number;
  message: string;
}

export type ArgsloginTargetByQrCode = {
  account_id: string;
  interaction_id: string;
};
export const { useLoginTargetByQrCodeMutation } = injectedRtkApi;

import {
  initSliceMeta as initSubscriptionSliceMeta,
  vsSubscriptionApiSlice,
} from '@react-mono/rtk/slices/api-vs-subscription';
import { configureStore } from '@reduxjs/toolkit';
import {
  apiMiscellaneousSlice,
  initSliceMeta as initApiMiscellaneous,
} from '@vs/rtk/slices/apiMiscellaneous';
import {
  initSliceMeta as initAccountSliceMeta,
  vsAccountApiSlice,
} from '@vs/rtk/slices/apiVsAccount';
import {
  initSliceMeta as initAccount2SliceMeta,
  vsAccount2ApiSlice,
} from '@vs/rtk/slices/apiVsAccount2';
import {
  initSliceMeta as initAuthSliceMeta,
  vsAuthApiSlice,
} from '@vs/rtk/slices/apiVsAuth';
import {
  initSliceMeta as initCommonSliceMeta,
  vsCommonApiSlice,
} from '@vs/rtk/slices/apiVsCommon';

import { deployEnv, isLocalDevMode } from '../constants';
import getUserAccessToken from '../utils/getUserAccessToken';
import { rootReducer } from './reducer';
import { securityApiSlice } from './slices/api/security/security-api';
import { rtkQueryErrorLogger } from './slices/middleware/errorHandler';

const meta = {
  deployEnv,
  getTokenFunc: getUserAccessToken,
};

initCommonSliceMeta(meta);
initAccountSliceMeta(meta);
initAccount2SliceMeta(meta);
initAuthSliceMeta(meta);
initSubscriptionSliceMeta(meta);
initApiMiscellaneous(meta);

export const store = configureStore({
  devTools: isLocalDevMode,
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([
      securityApiSlice.middleware,
      vsAccountApiSlice.middleware,
      vsAccount2ApiSlice.middleware,
      vsCommonApiSlice.middleware,
      vsAuthApiSlice.middleware,
      vsSubscriptionApiSlice.middleware,
      apiMiscellaneousSlice.middleware,
      rtkQueryErrorLogger,
    ]),
});

if (isLocalDevMode && import.meta.hot) {
  import.meta.hot.accept('./reducer.ts', module =>
    store.replaceReducer(module?.rootReducer)
  );
}

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { tokenRefreshMutex } from '@vs/oidc-client';
import { DocumentNode } from 'graphql';
import { ClientError } from 'graphql-request';

export type ReferenceOfGraphqlMeta = {
  _baseUrl: string;
  _getTokenFunc: () => MaybePromise<string | null>;
  _prepareCustomHeaders?: (headers: Headers) => MaybePromise<void>;
};

type ExtraOptions = Partial<Pick<ClientError, 'request' | 'response'>>;

type DynamicGraphBaseQueryFn = BaseQueryFn<
  { document: string | DocumentNode; variables?: any },
  unknown,
  unknown,
  ExtraOptions
>;
export const dynamicGraphqlBaseQuery =
  (referenceOfMeta: ReferenceOfGraphqlMeta): DynamicGraphBaseQueryFn =>
  async (queryArgs, baseQueryApi, extraOptions = {}) => {
    const { _baseUrl, _getTokenFunc, _prepareCustomHeaders } = referenceOfMeta;

    const bs = graphqlRequestBaseQuery({
      url: _baseUrl || '',
      prepareHeaders: async (headers, api) => {
        await tokenRefreshMutex.waitForUnlock();

        const token = await _getTokenFunc();
        headers.set('authorization', `Bearer ${token}`);
        _prepareCustomHeaders && (await _prepareCustomHeaders(headers));

        return headers;
      },
    });
    const result = Promise.resolve(bs(queryArgs, baseQueryApi, extraOptions));
    return result.then(payload => {
      // if (payload.error && isErrorResponseOfVsApi(payload.error)) {
      //   payload.error = transformErrorResponseOfVsApi(payload.error);
      // }
      return payload;
    });
  };

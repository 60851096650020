import { vsSubscriptionApiSlice } from '@react-mono/rtk/slices/api-vs-subscription';
import { combineSlices } from '@reduxjs/toolkit';
import { apiMiscellaneousSlice } from '@vs/rtk/slices/apiMiscellaneous';
import { vsAccountApiSlice } from '@vs/rtk/slices/apiVsAccount';
import { vsAccount2ApiSlice } from '@vs/rtk/slices/apiVsAccount2';
import { vsAuthApiSlice } from '@vs/rtk/slices/apiVsAuth';
import { vsCommonApiSlice } from '@vs/rtk/slices/apiVsCommon';
import { generalStateSlice } from '@vs/rtk/slices/genericGlobal';

import { securityApiSlice } from './slices/api/security/security-api';

export const rootReducer = combineSlices(
  generalStateSlice,
  securityApiSlice,
  vsAccountApiSlice,
  vsAccount2ApiSlice,
  vsCommonApiSlice,
  vsAuthApiSlice,
  vsSubscriptionApiSlice,
  apiMiscellaneousSlice
);

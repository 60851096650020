import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { tokenRefreshMutex } from '@vs/oidc-client';
import { DeployEnv } from '@vs/utils/envConfig';
import type { OidcClientSettings } from 'oidc-client-ts';

import {
  RtkErrorResponseOfVsApi,
  isErrorResponseOfVsApi,
  transformErrorResponseOfVsApi,
} from './errorHelper';

export type ArgsInitSliceMeta = {
  deployEnv: DeployEnv;
  getTokenFunc?: () => MaybePromise<string | null>;
  oidcConfig?: OidcClientSettings;
  prepareCustomHeaders?: (headers: Headers) => MaybePromise<void>;
};

type ExtraOptions = {
  isPublicEndpoint?: boolean;
  fallbackResponseOfNotFound?: unknown;
  checkIsNotFoundError?: (
    error: FetchBaseQueryError | RtkErrorResponseOfVsApi
  ) => boolean;
};

type DynamicBaseQueryFn = BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError | RtkErrorResponseOfVsApi,
  ExtraOptions
>;

export type ReferenceOfMeta = {
  _baseUrl: string | undefined;
  _getTokenFunc: (() => MaybePromise<string | null>) | undefined;
};

export function dynamicFetchBaseFactory(
  referenceOfMeta: ReferenceOfMeta,
  baseQueryOption?: FetchBaseQueryArgs
): DynamicBaseQueryFn {
  return async (args, api, extraOption = {}) => {
    const { _baseUrl, _getTokenFunc } = referenceOfMeta;

    const bs = fetchBaseQuery({
      baseUrl: _baseUrl ?? '',
      prepareHeaders: async (headers, api) => {
        if (!extraOption.isPublicEndpoint) {
          await tokenRefreshMutex.waitForUnlock();
          if (typeof _getTokenFunc === 'function') {
            // for legacy oidc client, we need to append the token manually
            const token = await _getTokenFunc();
            if (token) {
              headers.set('authorization', `Bearer ${token}`);
            }
          }
        }
        return headers;
      },
      credentials: 'omit',
      ...baseQueryOption,
    }) as DynamicBaseQueryFn;

    const result = await Promise.resolve(bs(args, api, extraOption));

    if (result.error && isErrorResponseOfVsApi(result.error)) {
      if (
        extraOption.fallbackResponseOfNotFound &&
        (typeof extraOption.checkIsNotFoundError === 'function'
          ? extraOption.checkIsNotFoundError(result.error)
          : result.error.status === 404)
      ) {
        // @ts-expect-error: this is run time mutation of RTK result, skip type issue
        result.data = extraOption.fallbackResponseOfNotFound;
        // @ts-expect-error: this is run time mutation of RTK result, skip type issue
        delete result.error;
      } else {
        result.error = transformErrorResponseOfVsApi(result.error);
      }
    }
    return result;
  };
}
